import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { Banner, mapToPageHeader } from "@components/Banner"
import { SEO, mapToSEO } from "@components/SEO"
import {
  SignupBannerWrapper,
  mapToKiwiVIPVisible,
} from "@components/SignupBanner"
import { FaqWithCategory, mapToFaqWithCategory } from "@components/FaqList"
import FAQFooterLinks from "@components/FAQFooterLinks/FAQFooterLinks"
import { Container } from "semantic-ui-react"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import { FaqsContainer } from "@components/FaqList/FaqList.styles"

const FAQs = (props: any) => {
  const { elements } = props.data.kontentItemFaqsPage
  const seo = mapToSEO(elements)
  const kiwiVip = mapToKiwiVIPVisible(elements)
  const metadata = mapToPageHeader(elements)
  let faqCategories = elements.faq_category.value
  const checkout = getCurrentCheckout()
  if(checkout && !checkout.isDealerOrder && faqCategories){
    faqCategories = faqCategories.filter((c: { codename: string }) => c.codename !== "dealer_checkout")
  }
  const faqs = mapToFaqWithCategory(props.data.allKontentItemFaqItem.edges)
  return (
    <Layout location={props.location}>
      <SEO {...seo} />
      <Banner {...metadata} isBiggerBanner={false} />
      <FaqsContainer darkTheme={false}>
        <FaqWithCategory categories={faqCategories} faqs={faqs} />
      </FaqsContainer>
      <FAQFooterLinks />
      {kiwiVip.visible && <SignupBannerWrapper version={kiwiVip.version} />}
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        apiUrl
      }
    }
    allKontentItemFaqItem {
      edges {
        node {
          elements {
            question {
              value
            }
            answer {
              value
            }
            faq_category {
              value {
                codename
                name
              }
            }
          }
        }
      }
    }
    kontentItemFaqsPage {
      elements {
        seo__noindex {
          value {
            name
            codename
          }
        }
        seo__nofollow {
          value {
            name
            codename
          }
        }
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        general_page_content__title {
          value
        }
        general_page_content__header_image {
          value {
            url
            description
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            name
            codename
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            name
            codename
          }
        }
        general_page_content__title_position {
          value {
            codename
          }
        }
        url {
          value
        }
        faq_category {
          value {
            codename
            name
          }
        }
        footer_title {
          value
        }
        footer_content {
          value
        }
        footer_contact_us_link {
          value
        }
      }
    }
  }
`
export default FAQs
