import React from 'react'
import { GenericBannerContainer } from '@components/GenericBannerContainer'
import theme from '@styles/theme'
import { Container } from 'semantic-ui-react'
import { H2 } from '@styles/Global.styles'
import { KpButton } from '@elements/index'
import { ButtonTypes } from '@utils/Constant'
import { ParagraphSection } from './FAQFooterLinks.styles'

const FAQFooterLinks = () =>{
    return (
        <GenericBannerContainer 
          padding={{
            mobile: {
                top: 60,
                bottom: 60
            },
            desktop: {
                top: 80,
                bottom: 80
            }
          }}
          backgroundColor={theme.brand.colors.white}>
        <Container textAlign="center">
          <H2>Still have a question?</H2>
          <KpButton id="faqfl-startlc" buttonType={ButtonTypes.Alternate} color={theme.brand.colors.green} onClick={()=>(window as Window & typeof globalThis).LC_API.open_chat_window()}>
            START A LIVE CHAT
          </KpButton>
          <ParagraphSection color={theme.brand.colors.darkGrey}>(9am to 5pm, Monday to Friday)</ParagraphSection>
          <ParagraphSection color={theme.brand.colors.black}>OR</ParagraphSection>
          <KpButton id="faqfl-cu" buttonType={ButtonTypes.Alternate} color={theme.brand.colors.blue} link='/contact-us'>
            CONTACT US
          </KpButton>
        </Container>
      </GenericBannerContainer>
    )
}

export default FAQFooterLinks;